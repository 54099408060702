// Generated by Framer (2a6858f)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFontsFromSharedStyle, Link, RichText, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as sharedStyle1 from "../css/lMyIlwvOB";
import * as sharedStyle from "../css/oITY0R6Nw";

const enabledGestures = {kdHbI9WQ7: {hover: true}};

const cycleOrder = ["kdHbI9WQ7", "nwjtizkSv"];

const serializationHash = "framer-M718o"

const variantClassNames = {kdHbI9WQ7: "framer-v-5ndn7e", nwjtizkSv: "framer-v-7w1v01"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {MobileNavigation: "nwjtizkSv", PrimaryNavigation: "kdHbI9WQ7"}

const getProps = ({color, height, id, link, tap, title, width, ...props}) => { return {...props, DQb0lOUIs: tap ?? props.DQb0lOUIs, fgZjzyq7C: color ?? props.fgZjzyq7C ?? "var(--token-545afc82-d790-4647-bdd0-17dca164eab2, rgb(0, 0, 0))", r7jC2slFO: title ?? props.r7jC2slFO ?? "Button", uigmtT8qY: link ?? props.uigmtT8qY, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "kdHbI9WQ7"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;title?: string;link?: string;color?: string;tap?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, r7jC2slFO, uigmtT8qY, fgZjzyq7C, DQb0lOUIs, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "kdHbI9WQ7", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTap13ulxmg = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (DQb0lOUIs) {const res = await DQb0lOUIs(...args);
if (res === false) return false;}
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = [sharedStyle.className, sharedStyle1.className]

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={uigmtT8qY} openInNewTab={false} smoothScroll><motion.a {...restProps} {...gestureHandlers} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-5ndn7e", className, classNames)} framer-28sxoi`} data-framer-name={"PrimaryNavigation"} data-highlight layoutDependency={layoutDependency} layoutId={"kdHbI9WQ7"} onTap={onTap13ulxmg} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({"kdHbI9WQ7-hover": {"data-framer-name": undefined}, nwjtizkSv: {"data-framer-name": "MobileNavigation"}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-hu60r5"} data-styles-preset={"oITY0R6Nw"}>Button</motion.p></React.Fragment>} className={"framer-1bs3jl5"} data-framer-name={"Title"} fonts={["Inter"]} layoutDependency={layoutDependency} layoutId={"Im2VJONiX"} style={{"--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline", "--framer-paragraph-spacing": "0px"}} text={r7jC2slFO} verticalAlignment={"top"} withExternalLayout {...addPropertyOverrides({nwjtizkSv: {children: <React.Fragment><motion.h3 className={"framer-styles-preset-1r5ytty"} data-styles-preset={"lMyIlwvOB"}>Button</motion.h3></React.Fragment>}}, baseVariant, gestureVariant)}/><motion.div className={"framer-eyiq2i"} layoutDependency={layoutDependency} layoutId={"EP78QJi3z"} style={{backgroundColor: fgZjzyq7C, opacity: 0}} variants={{"kdHbI9WQ7-hover": {opacity: 1}}}/></motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-M718o.framer-28sxoi, .framer-M718o .framer-28sxoi { display: block; }", ".framer-M718o.framer-5ndn7e { align-content: flex-start; align-items: flex-start; cursor: pointer; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 0px; height: 32px; justify-content: center; overflow: visible; padding: 0px; position: relative; text-decoration: none; width: min-content; }", ".framer-M718o .framer-1bs3jl5 { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", ".framer-M718o .framer-eyiq2i { bottom: 0px; flex: none; height: 2px; left: 0px; overflow: hidden; position: absolute; width: 2px; z-index: 1; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-M718o.framer-5ndn7e { gap: 0px; } .framer-M718o.framer-5ndn7e > * { margin: 0px; margin-bottom: calc(0px / 2); margin-top: calc(0px / 2); } .framer-M718o.framer-5ndn7e > :first-child { margin-top: 0px; } .framer-M718o.framer-5ndn7e > :last-child { margin-bottom: 0px; } }", ".framer-M718o.framer-v-5ndn7e.hover .framer-eyiq2i { height: 1px; right: 0px; width: unset; }", ...sharedStyle.css, ...sharedStyle1.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 32
 * @framerIntrinsicWidth 45.5
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","fixed"]},"nwjtizkSv":{"layout":["auto","fixed"]},"rcelRq_bG":{"layout":["auto","fixed"]}}}
 * @framerVariables {"r7jC2slFO":"title","uigmtT8qY":"link","fgZjzyq7C":"color","DQb0lOUIs":"tap"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const Framerut0BfGg7U: React.ComponentType<Props> = withCSS(Component, css, "framer-M718o") as typeof Component;
export default Framerut0BfGg7U;

Framerut0BfGg7U.displayName = "Navigation/ Navigation Item";

Framerut0BfGg7U.defaultProps = {height: 32, width: 45.5};

addPropertyControls(Framerut0BfGg7U, {variant: {options: ["kdHbI9WQ7", "nwjtizkSv"], optionTitles: ["PrimaryNavigation", "MobileNavigation"], title: "Variant", type: ControlType.Enum}, r7jC2slFO: {defaultValue: "Button", displayTextArea: false, title: "Title", type: ControlType.String}, uigmtT8qY: {title: "Link", type: ControlType.Link}, fgZjzyq7C: {defaultValue: "var(--token-545afc82-d790-4647-bdd0-17dca164eab2, rgb(0, 0, 0)) /* {\"name\":\"TextPrimary\"} */", title: "Color", type: ControlType.Color}, DQb0lOUIs: {title: "Tap", type: ControlType.EventHandler}} as any)

addFonts(Framerut0BfGg7U, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://framerusercontent.com/assets/5vvr9Vy74if2I6bQbJvbw7SY1pQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://framerusercontent.com/assets/EOr0mi4hNtlgWNn9if640EZzXCo.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://framerusercontent.com/assets/Y9k9QrlZAqio88Klkmbd8VoMQc.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://framerusercontent.com/assets/OYrD2tBIBPvoJXiIHnLoOXnY9M.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://framerusercontent.com/assets/JeYwfuaPfZHQhEG8U5gtPDZ7WQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://framerusercontent.com/assets/vQyevYAyHtARFwPqUzQGpnDs.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://framerusercontent.com/assets/b6Y37FthZeALduNqHicBT6FutY.woff2", weight: "400"}]}, ...getFontsFromSharedStyle(sharedStyle.fonts), ...getFontsFromSharedStyle(sharedStyle1.fonts)], {supportsExplicitInterCodegen: true})